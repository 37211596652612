import React from "react";
import { useNavigate } from "react-router-dom";
function Homepage(props) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col h-screen w-full  justify-center">
      <div className="text-center w-full px-28 space-y-4">
        {/* <h1>Coming soon ...</h1> */}
        <p className="text-5xl font-bold text-black "> <center>
        <img alt="WaveMaker" className="w-6 ml-4 w-52" src="/images/coming-soon.png"></img></center>
        
          World's First Ethereum Fair Launchpad Protocol for Everyone <br/><br/> Coming soon ... <br/>
        </p>
        <div className="px-32 w-full">
          <p className="text-md text-black">
            WaveMaker helps everyone to create their own tokens and token sales
            in few seconds. Tokens created on WaveMaker will be verified and
            published on explorer websites.
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-center mt-8 space-x-12">
        <button className="btn capitalize w-48" onClick={() => navigate("/createtoken")}>Create Token</button>
        <button className="btn capitalize w-48" onClick={() => navigate("/createpresale")}>Create Presale</button>
        <button className="btn capitalize w-48"onClick={() => navigate("/createfairlaunch")}>Create Fair-Launch</button>
        <button className="btn capitalize w-48"onClick={() => navigate("/viewallpools")}>View All Pools</button>
      </div>
      <div className="flex flex-row justify-center mt-8 space-x-6">
        <div className="flex flex-row w-fit bg-primary/20 py-4 rounded-md pb-6">
          <img alt="WaveMaker" className="w-6 ml-4 w-52" src="/images/home_icon_one.svg"></img>
          <div className="flex flex-col align-center space-y-2 mr-4">
            <p className="text-6xl text-black font-bold">Why</p>
            <p className="text-4xl text-black">WaveMaker</p>
            <div className="w-80">
              <p className="text-base text-black break-words">
                WaveMaker helps everyone to create their own tokens and token
                sales in few seconds. Tokens created on WaveMaker will be
                verified and published on explorer websites.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-fit bg-primary/20 py-4 rounded-md pb-6">
          <img alt="WaveMaker" className="w-6 ml-4 w-52" src="/images/home_icon_one.svg"></img>
          <div className="flex flex-col align-center space-y-2 mr-4">
            <p className="text-6xl text-black font-bold">One</p>
            <p className="text-4xl text-black">Suite for All</p>
            <div className="w-80">
              <p className="text-base text-black break-words">
                WaveMaker helps everyone to create their own tokens and token
                sales in few seconds. Tokens created on WaveMaker will be
                verified and published on explorer websites.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
